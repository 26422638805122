.dialog-enter {
  opacity: 0;
  transform: scale(0.9);
}
.dialog-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.dialog-exit {
  opacity: 1;
}
.dialog-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.dialog__hover {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: calc(var(--z-index-modal-content) * 2);
  background-color: black;
  opacity: 0.3;
  backdrop-filter: blur(50px);
}
.dialog__body-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: calc(var(--z-index-modal-content) * 2 + 1);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  padding: 0 9px;
}
.dialog__body {
  background-color: var(--color-card);
  box-sizing: border-box;
  width: 100%;
  max-width: 312px;
  border-radius: 24px;
  padding: 20px;
  text-align: center;
}
.dialog__actions-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  float: right;
}
.dialog__button {
  height: 50px;
  padding: 0 15px;

  &:not(:last-child) {
    margin-right: 20px;
  }
}
