
.main {
  transition: background-color 0.2s;
  &.padding-default {
    @apply px-21px;
  }
  &.padding-small {
    @apply px-9px;
  }
  &.text-medium {
    font-size: 15px;
  }
  &.text-bold {
    font-family: 'Roboto-Bold';
  }
  &.text-default {
     font-family: 'Roboto-Regular';
  }
  &.width-full {
    width: 100%;
  }
  &.height-default {
    height: 60px;
  }
  &.height-medium {
    height: 50px;
  }
  &.height-small {
    height: 36px;
  }
  &.height-thin {
    height: 27px;
  }
  &.radius-default {
    border-radius: 15px;
  }
  &.radius-small {
    border-radius: 9px;
  }
  &.radius-full {
    border-radius: 9999999px;
  }
  &.radius-top-default {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  &.content-type-center {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    min-width: 0;
    overflow: hidden;
  }
  &.content-type-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    min-width: 0;
    overflow: hidden;
  }
  &.type-main {
    color: var(--color-text-light);
    background-color: var(--color-pr);
    &:hover {
      background-color: var(--color-pr-dark);
    }
  }
  &.type-main-light {
    color: var(--color-pr);
    background-color: rgba(var(--color-pr-rgb), .21);
    &:hover {
      background-color: rgba(var(--color-pr-rgb), .3);
    }
  }
  &.type-dark {
    color: var(--color-text-light);
    background-color: var(--color-dark);
    &:hover {
      background-color: var(--color-dark-light);
    }
  }
  &.type-gray {
    color: var(--color-text);
    background-color: rgba(var(--color-dark-rgb), .09);
    &:hover {
      background-color: rgba(var(--color-dark-rgb), .15);
    }
  }
  &.type-white {
    color: var(--color-text);
    background-color: white;
    &:hover {
      background-color: rgba(var(--color-dark-rgb), .09);
    }
  }
  &.type-error {
    color: var(--color-error);
    background-color: rgba(var(--color-error-rgb), .15);
    &:hover {
      background-color: rgba(var(--color-error-rgb), .3);
    }
  }
  &.type-disabled {
    color: var(--color-text-light);
    background-color: rgba(var(--color-dark-rgb), .3);
    &:hover {
      background-color: rgba(var(--color-dark-rgb), .3);
    }
  }
}
