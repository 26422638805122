

.control {
  outline: none;
  width: 100%;
  background-color: var(--color-control-background);
  border-radius: 9px;
  padding: 11px 16px;
  font-size: 14px;
  line-height: 20px;
  &::placeholder {
    color: rgba(var(--color-dark-rgb), .3);
  }
  &.control_error {
    color: var(--color-error);
    &::placeholder {
      color: var(--color-error);
    }
  }
}
.wrapper {
  display: block !important;
}
