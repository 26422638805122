.container {
  cursor: pointer;
  position: relative;
}
.count {
  position: absolute;
  top: -11px;
  right: 16px;
  border-radius: 5.5px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(var(--color-dark-rgb), 0.2);
  color: var(--color-text-light);
  font-size: 12px;
  font-family: 'Roboto-Black';
  height: 19px;
  line-height: 19px;
  text-align: center;
  padding: 0 6px;
  min-width: 26px;
}