

.title {
  font-size: 17px;
  font-family: 'Roboto-Black';
  line-height: 1.17;
}
.subtitle {
  font-size: 12px;
  font-family: 'Roboto-Medium';
  line-height: 1.75;
  color: rgba(var(--color-dark-rgb), .3);

}
.price {
  padding: 2px 7px;
  line-height: 1.4;
  font-size: 17px;
  font-family: 'Roboto-Medium';
  line-height: 1.24;
}
.price_emphasised {
  background-color: #ffe17b;
}
.price_crossed {
  position: relative;
  &::after {
    width: 100%;
    left: 0;
    top: 12px;
    position: absolute;
    content: "";
    rotate: -5deg;
    border-bottom: 2px solid rgba(var(--color-error-rgb), 0.5);
  }
}
.price_currency {
  padding-left: 3px;
  color: rgba(var(--color-dark-rgb), .3);
}
.price-subtitle {
  font-size: 12px;
  font-family: 'Roboto-Medium';
  line-height: 1.75;
  color: rgba(var(--color-dark-rgb), .3);
}
.action {
  height: 36px;
}

.container {
  display: flex;
  align-items: center;
}
.container-row {
  width: 100%;
  max-width: 430px;
  gap: 15px;
  .title {
    flex: 1;
  }
  .content {
    gap: 9px;
  }
}
.container-column {
  flex-direction: column;
  .content {
    flex-direction: column;
  }
  .subtitle {
    margin-bottom:6px;
  }
  .action {
    margin-top: 6px;
  }
  &:not(:has(.subtitle)) {
    .title {
      margin-bottom: 6px;
    }
  }
}
.content {
  display: flex;
  align-items: center;
}

@media (min-width: 0px) and (max-width: 599px) {
  .title {
    font-size: 15px;
    line-height: 1.2;
  }
  .subtitle {
    font-size: 11px;
    line-height: 1.18;
  }
  .price {
    font-size: 15px;
  }
  .price-subtitle {
    font-size: 11px;
    line-height: 1.18;
  }
  .container-row {
    gap: 9px;
    .title {
      padding-right: 7px;
    }
  }
}