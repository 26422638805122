
.lds-spinner {
  color: gray;
  display: inline-block;
  position: relative;
  @keyframes rot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.lds-inner-spinner {
  animation: 1s linear 0s normal none infinite running rot;
  -webkit-animation: 1s linear 0s normal none infinite running rot;
}

.fixed-element {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  .lds-spinner {
    margin: auto;
  }
}

.block-element {
  position: relative;
  width: 100%;
  padding: 10px 0;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;

  .lds-spinner {
    margin: auto;
  }
}
.absolute-element {
  position: absolute;
  padding: 0;
  height: 100%;
}
