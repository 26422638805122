.container {
  @apply mb-2;
  font-size: 17px;
  line-height: 1.41;
  color: var(--color-text);
}
.error {
  color: var(--color-error);
  &::before {
    content: "\00a0•\00a0";
  }
}