.title {
  margin-bottom: 20px;
}
.info {
  width: 100%;
  tr {
    vertical-align: top;
    &:not(:last-child) {
      border-bottom: 2px solid var(--color-border-strict);
    }
    td {
      padding: 20px 0;
      &:first-child {
        width: 50px;
        padding-right: 15px;
        color: rgba(var(--color-dark-rgb), .3);
      }
      &:last-child {
        padding-left: 15px;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 599px) {
  .title {
    margin-bottom: 12px;
  }
}