

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./shared/fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./shared/fonts/Roboto-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(./shared/fonts/Roboto-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'), url(./shared/fonts/Roboto-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(./shared/fonts/Gilroy-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./shared/fonts/Gilroy-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: local('Gilroy-ExtraBold'), url(./shared/fonts/Gilroy-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Black';
  src: local('Gilroy-Black'), url(./shared/fonts/Gilroy-Black.ttf) format('truetype');
}


:root {
  --color-error: #bf0000;
  --color-background: #b2b2b2;
  --color-text: #000000;
  --color-text-light: #ffffff;
  --color-card: #ffffff;
  --color-card-gray: #e8e8e8;
  --color-block-gray: #f0f0f0;
  --color-modal-background: #e8e8e8;
  --color-pr-light: #f0d5fa;
  --color-pr: #b22bea;
  --color-pr-dark: #8d00c7;
  --color-dark: #000000;
  --color-dark-light: #383838;
  --color-control-background: rgba(0, 0, 0, 0.09);
  --color-avatar-background: #d8d8d8;
  --color-border: rgba(0, 0, 0, .12);
  --color-border-strict: #e8e8e8;
  --color-pr-rgb: 178, 43, 234;
  --color-dark-rgb: 0, 0, 0;
  --color-error-rgb: 213, 0, 0;
  --z-index-header: 100;
  --z-index-modal: 1000;
  --z-index-modal-content: 1100;
  --z-index-preloader: 1200;
  --scroll-padding: 15px;
}

body {
  background-color: var(--color-modal-background);
  font-size: 17px;
  margin: 0;
  font-family: 'Roboto-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  // /* Стилизация скролла START */
  // p,
  // div:not(.modal-container-wrapper),
  // div:not(.scrollbar-measure)
  // span,
  // nav,
  // a,
  // header,
  // footer {
  //   scrollbar-width: none;
  //   scrollbar-color: rgba(0, 161, 32, 0.3) #f0f0f0;
  // }

  // /* Works on Chrome/Edge/Safari */
  // p,
  // div:not(.scrollbar-measure)
  // div:not(.modal-container-wrapper),
  // span,
  // nav,
  // a,
  // header,
  // footer {
  //   &::-webkit-scrollbar {
  //     width: 3px;
  //     height: 3px;
  //   }
  //   &::-webkit-scrollbar-track {
  //     background: #f0f0f0;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     background-color: rgba(0, 161, 32, 0.3);
  //     border-radius: 20px;
  //     border: 1px solid transparent;
  //   }
  // }
  /* Стилизация скролла END */
  
}


.with-modal {
  overflow: hidden;
  padding-right: var(--scroll-padding);
  .header-desktop {
    width: calc(100% - var(--scroll-padding));
  }
}
.scrollbar-measure {
	width: 100px;
	height: 100px;
	overflow: scroll;
	position: absolute;
	top: -9999px;
}


@layer components {
  .overflow-x-auto-hover {
    overflow-x: hidden;
    &:hover {
      overflow-x: auto;
    }
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .one-line-cutted-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .modal-form {
    width: 100%;
    max-width: 396px;
    @apply mx-auto;
  }
  @media (min-width: 0px) and (max-width: 599px) {
    .modal-form {
      max-width: unset;
      padding-left: 9px;
      padding-right: 9px;
    }
  }
}

