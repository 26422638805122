.container {
  display: flex;
  gap: 8px;
}
.card {
  flex: 1;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  padding: 0 21px 21px;
  background-color: var(--color-card-gray);
  border-radius: 24px;
}
.card__icon-container {
  display: flex;
  flex: 1;
  justify-content: center;
}
.card__icon {
  display: block;
  margin-top: -44px;
  width: 115px;
  object-fit: contain;
}
.card__title {
  font-size: 30px;
  font-family: 'Gilroy-Black';
}
.card__description {
  font-size: 15px;
}