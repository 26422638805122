.color-default {
  background-color: var(--color-card);
}
.color-gray {
  background-color: var(--color-card-gray)
}
.color-light-gray {
  background-color: rgba(var(--color-dark-rgb), .09);
}
.radius-default {
  border-radius: 30px;
}
.radius-medium {
  border-radius: 24px;
}
.radius-small {
  border-radius: 15px;
}
.radius-thin {
  border-radius: 12px;
}
.top-radius-default {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.top-radius-medium {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.top-radius-small {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.top-radius-thin {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.bottom-radius-default {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.bottom-radius-medium {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}
.bottom-radius-small {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.bottom-radius-thin {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.padding-default {
  padding-top: 40px;
  padding-bottom: 40px;
}
.padding-medium {
  padding-top: 21px;
  padding-bottom: 21px;
}
.padding-small {
  padding-top: 12px;
  padding-bottom: 12px;
}
.padding-tiny {
  padding-top: 9px;
  padding-bottom: 9px;
}


@media (min-width: 0px) and (max-width: 599px) {
  .radius-default {
    border-radius: 24px;
  }
  .top-radius-default {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .bottom-radius-default {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  .padding-default {
    padding-top: 21px;
    padding-bottom: 21px;
  }
}