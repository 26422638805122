.wrapper-light {
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 473px;
    background-color: var(--color-pr);
    &::after {
      position: absolute;
      background-color: black;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 179px;
      content: '';
      background-image: linear-gradient(to bottom, var(--color-pr), var(--color-modal-background));
    }
  }
}
.wrapper-dark {
  .container {
    background: #3d3d3d;
  }
}
.container {
  --layout-offset-top: 113px;
  padding-top: var(--layout-offset-top);
  min-height: 100vh;
  position: relative;
  z-index: 1;
}
.background-dark {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(61,61,61,1) 70%);
}
.background-light {
  background: rgb(232,232,232);
  background: linear-gradient(0deg, rgba(232,232,232,1) 45%, rgba(178,43,234,1) 75%);
}

@media (min-width: 0px) and (max-width: 599px) {
  .container {
    --layout-offset-top: 79px;
  }
}