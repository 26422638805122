/* Customize the label (the container) */
.container {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  min-height: 24px;
  display: flex;
  align-items: center;
  min-width: 0;
  width: 100%;
}
.container_width-full {
  width: 100%;
}
.container_width-content {
  width: fit-content;
}
.label {
  padding-left: 9px;
}


.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: var(--color-card-gray);
  border: 2px solid var(--color-border);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.checkmark_checked {
  border: none;
  background-color: var(--color-pr);
}
.container .checkmark_checked:after {
  display: block;
}

.container:hover {
  .checkmark {
    background-color: var(--color-card);
    border: 2px solid var(--color-pr-dark);
  }
  .checkmark_checked {
    border: none;
    background-color: var(--color-pr-dark);
  }
}