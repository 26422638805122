.wrapper {
  position: fixed;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-header);
  width: 100%;
}
.container-wrapper {
  width: 100%;
  padding: 0 21px;
}
.container {
  height: 60px;
  width: 100%;
  max-width: 772px;
  margin: 0 auto;
  padding: 0 21px;
  background-color: rgba(var(--color-dark-rgb), .8);
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  box-shadow: 0 21px 57px 0 rgba(0, 0, 0, 0.3);
}
.triangle-right {
  position: absolute;
  top: 100%;
  right: 0;
}
.triangle-left {
  position: absolute;
  top: 100%;
  left: 0;
}
.action-wrapper {
  width: 70px;
}
.action-container {
  color: var(--color-text-light);
  font-family: 'Gilroy-Bold';
  gap: 6px;
  display: flex;
  align-items: center;
}
@media (min-width: 0px) and (max-width: 599px) {
  .wrapper {
    top: 0;
  }
  .container-wrapper {
    max-width: unset;
    padding: 0;
  }
  .container {
    padding: 0 11px;
    background-color: rgba(var(--color-dark-rgb), .3);
    border-radius: unset;
    box-shadow: unset;
  }
  .container_dark-on-mobile {
    background-color: var(--color-dark);
  }
  .action-container {
    font-size: 15px;
  }
}