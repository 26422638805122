.modal {
  .content {
    transition: opacity 0.2s, transform 0.2s;
  }
  &:global(.preEnter) {
    .content {
      opacity: 0;
      transform: scale(0.5);
    }
  }
  &:global(.exiting) {
    .content {
      opacity: 0;
      transform: scale(0.5);
    }
    .overlay {
      display: none;
    }
  }
}

.wrapper {
  position: fixed;
  z-index: var(--z-index-modal);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: rgba(var(--color-dark-rgb), .7);
  backdrop-filter: blur(50px);
  z-index: var(--z-index-modal);
}
.container-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  overflow-y: auto;
  z-index: var(--z-index-modal-content);
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: auto;
  position: relative;
}
.content {
  width: 100%;
  position: relative;
}

.option {
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  &:hover {
    color: var(--color-pr)
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
  }
}
.option_selected {
  font-family: 'Roboto-Bold';
  color: var(--color-pr)
}
.control-wrapper {
  cursor: pointer;
  &.control-wrapper_disabled {
    cursor: inherit;
  }
}
.control {
  outline: none;
  width: 100%;
  background-color: var(--color-control-background);
  border-radius: 9px;
  padding: 11px 16px;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.control_placeholder {
    color: rgba(var(--color-dark-rgb), .3);
  }
  &.control_error {
    color: var(--color-error);
    &::placeholder {
      color: var(--color-error);
    }
  }
}