.type-supertitle {
  font-size: 17px;
  font-family: 'Gilroy-ExtraBold';
  &.align-unset {
    text-align: center;
  }
}
.type-title,
.type-title-strict {
  font-size: 54px;
  line-height: 1.22;
 font-family: 'Gilroy-ExtraBold';
  &.align-unset {
    text-align: center;
  }
}
.type-main {
  font-size: 17px;
  line-height: 1.41;
}
.type-block-title {
  font-size: 36px;
  font-family: 'Gilroy-ExtraBold';
  line-height: 1.25;
}
.type-block-subtitle {
  font-size: 24px;
  font-family: 'Gilroy-ExtraBold';
  line-height: 1.25;
}
.type-element-title {
  font-family: 'Gilroy-Bold';
  font-size: 20px;
  line-height: 1.2;
}
.type-small-text {
  font-size: 15px;
  line-height: 1.4;
}
.type-tiny-text {
  font-size: 10px;
  line-height: normal;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-left-on-mobile {
  text-align: center;
}
.text-main {
  color: var(--color-pr);
}
.text-dark {
  color: var(--color-text);
}
.text-light {
  color: var(--color-text-light);
}
.text-opacity-30 {
  color: rgba(var(--color-dark-rgb), .3)
}
.text-error {
  color: var(--color-error);
}
.space-nowrap {
  white-space: nowrap;
}
.space-pre {
  white-space: pre;
}
.line-height-normal {
  line-height: normal;
}
.weight-bold {
  font-family: 'Roboto-Bold';
}

@media (min-width: 0px) and (max-width: 599px) {
  .type-supertitle {
    &.align-unset {
      text-align: left;
    }
  }
  .type-title {
    font-size: 36px;
    line-height: 1.25;
    &.align-unset {
      text-align: left;
    }
  }
  .type-list-text {
    font-size: 15px;
  }
  .type-small-mobile-text {
    font-size: 15px;
  }
  .align-center-on-mobile {
    text-align: center;
  }
  .align-left-on-mobile {
    text-align: left;
  }
}