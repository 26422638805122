.select-wrapper {
  .select__control {
    background-color: var(--color-control-background);
    border-radius: 9px;
    border: 0 !important;
    outline: none;
    // This line disable the blue border
    box-shadow: none;
    &:hover {
      border: 0 !important;
      box-shadow: none;
    }
  }
  .select__value-container {
    padding: 11px 16px;
    margin: 0;
  }
    
  .select__indicator-separator {
    display: none;
  }
  .select__single-value,
  .select__placeholder {    
    outline: none;
    width: 100%;
    
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
  }
  .select__placeholder {
    color: rgba(var(--color-dark-rgb), .3);
  }
  .select__option {
    cursor: pointer;
  }
  .select__option--is-focused,
  .select__option--is-selected {
    color: var(--color-pr);
    background-color: unset;
  }
  .select__option--is-selected {
    font-family: 'Roboto-Bold';
  }
  .select-menu-list__small {
    max-height: 120px;
  }
}