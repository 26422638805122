.wrapper {
  display: flex;
  align-items: center;
  min-width: 0;
  gap: 6px;
}
.color {
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 12px;
}
