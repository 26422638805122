.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.avatar-container {
  padding: 13px 32.5px 52px;

  margin: 0 auto;
  position: relative;
}
.avatar-container__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.33;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  background-color: var(--color-pr);
}
.avatar {
  position: relative;
  background-color: rgba(var(--color-dark-rgb), .09);
  z-index: 2;
}
.user-info {
  margin-top: -20px;
}
.info-container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 43px;
}