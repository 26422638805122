.icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.icon {
  max-width: 335px;
  rotate: -3deg;
  // box-shadow: 0 40px 55px 0 rgba(0, 0, 0, 0.1);
  -webkit-filter: drop-shadow(0px 40px 25px rgba(0, 0, 0, .24));
  filter: drop-shadow(0px 40px 25px rgba(0, 0, 0, .24));
}

.info-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-top: 2px solid var(--color-border-strict);
}

.description {
  margin-bottom: 11px;
  &:last-child {
    margin-bottom: 50px;
  }
}
.action {
  margin-bottom: 19px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 0px) and (max-width: 599px) {
  .action {
    margin-left: unset;
    margin-right: unset;
  }
}