.container {
  background-color: var(--color-avatar-background);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.radius-default {
  border-radius: 15px;
}
.radius-large {
  border-radius: 36px;
}
.radius-full {
  border-radius: 9999px;
}
