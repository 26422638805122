.modal {
  .container-wrapper {
    transition: top 0.2s;
  }
  &:global(.preEnter) {
    .container-wrapper {
      top: 100vh; 
    }
  }
  &:global(.exiting) {
    .container-wrapper {
      top: 100vh;
    }
    .overlay {
      display: none;
    }
  }
}

.wrapper {
  position: fixed;
  z-index: var(--z-index-modal);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: rgba(var(--color-dark-rgb), .7);
  backdrop-filter: blur(50px);
  z-index: var(--z-index-modal);
}
.container-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  overflow-y: auto;
  z-index: var(--z-index-modal-content);
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: auto;
  position: relative;
}
.header {
  position: sticky;
}
.content {
  width: 100%;
  max-width: 800px;
  position: relative;
  height: auto;
  min-height: 100vh;
}
.content-color-default {
  background-color: var(--color-modal-background);
}
.content-color-white {
  background-color: white;
}
.offset-top-default {
  padding-top: 50px;
}
.offset-bottom-default {
  padding-bottom: 50px;
}


.close {
  position: absolute;
  top: 42px;
  right: 30px;
  cursor: pointer;
  height: fit-content;
}

@media (min-width: 0px) and (max-width: 599px) {
  .offset-top-default {
    padding-top: 18px;
  }
  .offset-bottom-default {
    padding-bottom: 150px;
  }
}
