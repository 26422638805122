.content {
  width: 100%;
  @apply mx-auto;
}
.padding-default {
  padding-left: 61px;
  padding-right: 61px;
}
.padding-medium {
  @apply px-21px;
}
.padding-small {
  @apply px-9px
}
.content-default {
  max-width: 1200px;
}
.content-tiny {
  max-width: 252px;
}
.content-modal-form {
  max-width: 396px;
}
.content-modal-form-wide {
  max-width: 434px;
}
.content-modal-tiny {
  max-width: 498px;
}
.content-modal {
  max-width: 576px;
}

@media (min-width: 0px) and (max-width: 599px) {
  .content-default,
  .content-tiny,
  .content-modal,
  .content-modal-tiny,
  .content-modal-form,
  .content-modal-form-wide {
    max-width: unset;
  }
  .padding-default {
    @apply px-21px;
  }
  .content-tiny,
  .content-modal,
  .content-modal-tiny,
  .content-modal-form,
  .content-modal-form-wide,
  .padding-mobile-small {
    @apply px-9px;
  }
}