.container {
  overflow-x: auto;
}
.slots {
  width: 100%;
  min-width: fit-content;
  .slot {
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 2px solid var(--color-border);
    }
    td {
      padding-top: 12px;
      padding-bottom: 12px;
      background: var(--color-card);
      &:first-child {
        padding-right: 54px;
      }
      &:last-child {
        width: 100%;
      }
    }
    &.slot_expired {
      opacity: 0.3;
    }
  }
}
.clients {
  display: flex;
  min-width: 0;
  width: fit-content;
  gap: 27px;
}

@media (min-width: 0px) and (max-width: 599px) {
  .slots {
    .slot {
      td {
        &:first-child {
          padding-right: 21px;
        }
      }
    }
  }
  .clients {
    gap: 21px;
  }
}